.smaller-h2 {
  font-family: "Goldplay";
  text-transform: uppercase;
  font-size: 22pt;
  letter-spacing: 0;
  text-shadow: none;
  margin: 0 0 10px 0;
}

.projects {
  display: flex;
  width: 100%;
}

.images {
  width: 100%;
  display: block;
  text-align: center;
  background-color: #fff;
  padding: 15px;
  border-radius: 20px;
}

.image {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
}

.images img {
  max-width: 100%;
  height: auto;
}

.buttons {
  text-align: center;
  margin-bottom: 5em;
}

button {
  padding: 15px 10px;
  cursor: pointer;
  border: 0px;
  margin: 15px;
  border-radius: 15px;
  /* width: 150px; */
  background-color: #161b22;
  color: white;
  font-weight: bold;
  font-size: 12pt;
  /* font-family: "Playfair Display", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 14pt;
  text-transform: uppercase;
  box-shadow: 2px 1px 0px #53f3e3, -2px -1px 0px tomato;
}

button:hover {
  box-shadow: 2px 1px 0px tomato, -2px -1px 0px #53f3e3;
}

button:active {
  box-shadow: 2px 1px 0px chartreuse, -2px -1px 0px chartreuse;
}
