.nav-bar {
  width: 35%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgb(200, 200, 200, 0.3);
  padding-right: 5%;
  padding-top: 30vh;
}

.nav-link a {
  /* color: #384456; */
  color: #818181;
}

.nav-link a:hover {
  color: #000;
  font-size: 14pt;
  text-shadow: 2px 1px 0px #53f3e3, -2px -1px 0px tomato;
}

.nav-link {
  margin-top: 35px;
  /* font-family: "Visby", sans-serif; */
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 14pt;
  /* text-shadow: 1px 0.5px 0px #53f3e3, -1px -0.5px 0px tomato; */
}

.nav-link-active {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: -2px;
  color: #000 !important;
  font-size: 20pt !important;
  text-shadow: 2px 1px 1px #53f3e3, -2px -1px 1px tomato;
  /* opacity: 75%; */
}
