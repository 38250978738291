@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;800&display=swap");

@font-face {
  font-family: "Goldplay";
  src: url("../assets/goldplay/goldplay-regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Goldplay";
  src: url("../assets/goldplay/goldplay-semi-bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Goldplay Medium";
  src: url("../assets/goldplay/goldplay-medium.otf");
}

@font-face {
  font-family: "Visby";
  src: url("../assets/visby/VisbyThin.woff");
  font-weight: normal;
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  color: #3c3c3c;
  font-family: "Goldplay", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background-color: #f8f8f8;
  cursor: default;
}

* {
  box-sizing: border-box;
}

h1 {
  color: #000;
  font-size: 5.5em;
  margin: 0 0 25px 0;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 800;
  letter-spacing: -15px;
  text-shadow: -5px 0px 0 #e9e9e9, 1.5px -1px 0 #4bffed;
  text-decoration: underline;
}

h2 {
  font-size: 3em;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 800;
  letter-spacing: -6px;
  text-shadow: -5px 0px 0 #e4e4e4;
}

a {
  color: #3c3c3c;
  text-decoration: none;
  font-weight: bold;
  transition: 0.3s;
}

a:hover {
  color: #818181;
}
a:active {
  color: tomato;
}

.container {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

.content {
  flex: 1;
  max-height: 100vh;
  padding: 20vh 0 50px 2.5%;
  overflow-y: auto;
}

.content-inner {
  width: 80%;
  max-width: 1000px;
}
