.main-cont {
  display: flex;
  width: 100%;
  padding-top: 10vh;
}

.main-left {
  width: 50%;
  background-image: url('../assets/roofbw.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 95%;
}

.main-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.main-right h2, h3 { margin: 10px 0 0 0 }

.main-name {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.main-text {
  margin-top: 15px;
  color: #818181;
  text-align: right;
  line-height: 1.5em;
}

.links-header {
  margin-top: 7vh;
  text-align: center;
}

.socials {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.socials a {
  margin-right: 15px;
  font-size: 18pt;
  transition: transform 0.3s ease-in-out;
}

.socials a:hover {
  transform: scale(1.5);
}

.github:hover { color: #2DA043 }
.linked:hover { color: #0966C2 }
.fb:hover { color: #1C74E4 }
.ig:hover { color: #F3094E }
.twitter:hover { color: #188CD8 }

.email-link {
  text-align: center;
  margin-top: 25px;
}
.email-link a { color: tomato }